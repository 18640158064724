<template>
  <content-comp :backTitle="imgUrl" :listItems="listItem">
    <template>
      <div slot="tagpage">
        <div class="flexBox">
          <div class="inline-display" v-for="(items, i) in tagPage" :key="i">
            <div
              class="item"
              :class="phase === items.phase ? 'active' : ''"
              @click="handleChange(items)"
            >
              {{ $t(items.name) }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </content-comp>
</template>
<script>
import newsList from "@/mixins/news/newsList";
import {
  allPageList,
  newsPageList,
  MaintenanceList,
  updatedList,
  eventPageList,
} from "../../configs/news-content";
import ContentComp from "../../components/ContentComp.vue";

export default {
  mixins: [newsList],
  components: {
    ContentComp,
  },
  data() {
    return {
      phase: JSON.parse(sessionStorage.getItem("index") || 1),
      // phase:1,
      // listItem: [],
    };
  },
  computed: {
    imgUrl() {
      return `/static/img/news/newsBackground_${this.$store.state.language}.png`;
    },
    tagPage() {
      return [
        {
          name: "news.p2",
          phase: 1,
          news: this.formatNews(
            this.$store.state.allNewsList,
            allPageList(this.$store.state.language)
          ),
        },
        {
          name: "news.p3",
          phase: 2,
          news: this.formatNews(
            this.$store.state.allNewsList,
            newsPageList(this.$store.state.language),
            1
          ),
        },
        {
          name: "news.p4",
          phase: 3,
          news: this.formatNews(
            this.$store.state.allNewsList,
            MaintenanceList,
            2
          ),
        },
        {
          name: "news.p5",
          phase: 4,
          news: this.formatNews(this.$store.state.allNewsList, updatedList, 3),
        },
        {
          name: "news.p6",
          phase: 5,
          news: this.formatNews(
            this.$store.state.allNewsList,
            eventPageList(this.$store.state.language),
            4
          ),
        },
      ];
    },
    listItem() {
      return this.tagPage[this.phase - 1].news;
    },
  },
  methods: {
    handleChange(items) {
      this.phase = items.phase;
      sessionStorage.setItem("index", JSON.stringify(items.phase));
    },
  },
  mounted() {
    this.$store.dispatch("getNewAllList", this);
    this.handleChange(this.tagPage[0]);
  },
  watch: {
    "$i18n.locale"() {
      this.handleChange(this.tagPage[this.phase - 1]);
    },
  },
};
</script>
